var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaQuery } from "react-responsive";
import style from "./modalPublication.module.css";
import { ModalBlurWrapper } from "../../Helpers/ModalBlurWrapper/ModalBlurWrapper";
import close_icon from "../../../assets/img/close_modal_icon.png";
import { useState } from "react";
import styled from "styled-components";
import { useLocation, useSelector } from "../cardImports";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { usePinPublicationInModalMutationInCard } from "../QueryMutations/QueryMutations";
var InputPinDate = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  outline: none;\n  border: 1px solid #gray;\n  color: gray;\n  height: 40px;\n  width: 300px;\n"], ["\n  outline: none;\n  border: 1px solid #gray;\n  color: gray;\n  height: 40px;\n  width: 300px;\n"])));
var ButtonPinDate = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  outline: none;\n  border: none;\n  height: 40px;\n  width: 300px;\n  background: ", ";\n  color: white;\n"], ["\n  outline: none;\n  border: none;\n  height: 40px;\n  width: 300px;\n  background: ", ";\n  color: white;\n"])), function (props) { return (props.isPinned ? "red" : "blue"); });
export var ModalPinPublication = function (_a) {
    var props = _a.props, setClose = _a.setClose;
    var isMobile = useMediaQuery({
        query: "(max-width: 700px)",
    });
    var currentDate = dayjs();
    var AuthorizedUserId = useSelector(function (state) { var _a; return (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo; }).id;
    var location = useLocation();
    var _b = useState(currentDate), pinDate = _b[0], setPinDate = _b[1];
    var _c = useState(null), pinTime = _c[0], setPintime = _c[1];
    //pin publication
    var pinPublicationMutation = usePinPublicationInModalMutationInCard();
    var handlePinnedPublication = function (id, page, authorId, authorizedId) {
        var dateToPost = String(dayjs(pinDate).format("YYYY-MM-DD") + "T" + dayjs(pinTime).format("HH:mm") + "*03:00");
        switch (page) {
            case "/news/":
                pinPublicationMutation.mutate({
                    id: id,
                    order: 1,
                    page: page,
                    isPinned: (props === null || props === void 0 ? void 0 : props.order) ? true : false,
                    date: dateToPost,
                });
                setClose(false);
                break;
            case "/events/":
                pinPublicationMutation.mutate({
                    id: id,
                    order: 2,
                    page: page,
                    isPinned: (props === null || props === void 0 ? void 0 : props.order) ? true : false,
                    date: dateToPost,
                });
                setClose(false);
                break;
            case "/articles/":
                pinPublicationMutation.mutate({
                    id: id,
                    order: 3,
                    page: page,
                    isPinned: (props === null || props === void 0 ? void 0 : props.order) ? true : false,
                    date: dateToPost,
                });
                setClose(false);
                break;
            case "/companies_page/":
                pinPublicationMutation.mutate({
                    id: id,
                    order: 4,
                    page: page,
                    isPinned: (props === null || props === void 0 ? void 0 : props.order) ? true : false,
                    date: dateToPost,
                });
                setClose(false);
                break;
            case "/vacancy/":
                pinPublicationMutation.mutate({
                    id: id,
                    order: 5,
                    page: page,
                    isPinned: (props === null || props === void 0 ? void 0 : props.order) ? true : false,
                    date: dateToPost,
                });
                setClose(false);
                break;
        }
    };
    console.log("pin minute", pinTime === null || pinTime === void 0 ? void 0 : pinTime.minute());
    return (_jsx(ModalBlurWrapper, __assign({ onClickClose: function () { return setClose(false); } }, { children: _jsxs("div", __assign({ className: style.modalAuth, onClick: function (e) { return e.stopPropagation(); } }, { children: [_jsxs("div", __assign({ className: style.modalAuthLogo }, { children: [isMobile ? _jsx("img", { onClick: function () { return setClose(false); }, className: style.modalAuthCloseIcon, src: close_icon, alt: "close icon" }) : null, !(props === null || props === void 0 ? void 0 : props.order) ? (_jsxs(_Fragment, { children: [_jsx(LocalizationProvider, __assign({ locale: "en", dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: pinDate, onChange: function (newValue) { return setPinDate(newValue); }, renderInput: function (params) { return _jsx(TextField, __assign({}, params)); } }) })), _jsx(LocalizationProvider, __assign({ locale: "ru", dateAdapter: AdapterDayjs }, { children: _jsx(TimePicker, { value: pinTime, onChange: setPintime, renderInput: function (params) { return _jsx(TextField, __assign({}, params)); } }) }))] })) : null, _jsx(ButtonPinDate, __assign({ isPinned: (props === null || props === void 0 ? void 0 : props.order) ? true : false, onClick: function () { var _a; return handlePinnedPublication(props === null || props === void 0 ? void 0 : props.id, location.pathname, (_a = props === null || props === void 0 ? void 0 : props.author) === null || _a === void 0 ? void 0 : _a.id, AuthorizedUserId); } }, { children: (props === null || props === void 0 ? void 0 : props.order) ? "Открепить" : "Закрепить" }))] })), isMobile ? null : _jsx("img", { onClick: function () { return setClose(false); }, className: style.modalAuthCloseIcon, src: close_icon, alt: "close icon" })] })) })));
};
var templateObject_1, templateObject_2;
