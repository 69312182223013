var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './StartupCardFooter.module.css';
function StartupCardFooter(_a) {
    var stage_complete = _a.stage_complete, stage_count = _a.stage_count, budget = _a.budget;
    var percentage = (stage_complete / stage_count) * 100;
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: styles.startup_stepsLine }, { children: [_jsx("div", { className: styles.startup_stepsLine_fill, style: { width: "".concat(percentage, "%") } }), _jsxs("p", __assign({ className: styles.startup_lineText }, { children: ["\u042D\u0442\u0430\u043F\u044B \u0440\u0430\u0437\u0432\u0438\u0442\u0438\u044F: ", stage_complete, "/", stage_count] }))] })), _jsxs("div", __assign({ className: styles.startup_money }, { children: [_jsx("p", __assign({ className: styles.startup_title }, { children: "\u0417\u0430\u043F\u0440\u043E\u0441 \u043F\u0440\u043E\u0435\u043A\u0442\u0430:" })), _jsx("div", __assign({ className: styles.startup_money_count }, { children: budget ? "".concat(budget, " USD") : 'Не указан' }))] }))] }));
}
export default StartupCardFooter;
