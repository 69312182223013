// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.os48Mr4mcbhyLXB73ED7 {
  margin-bottom: 15px;
  width: 100%;
}

.r5EEbPSJuc1Mx5Xmp0Vp {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--main-Gray-3-color);
}

.XuihZF2dfQLteQa8wqLg {
  display: flex;
  width: 98%;
  max-width: 660px;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 5px;
}

.XuihZF2dfQLteQa8wqLg img {
  margin-right: 3px;
  display: flex;
  align-items: center;
}

.e_Ur4uvvmvbleYCjK66l {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.Otf5frTQOhKGaNnBN729 {

  width: 304px;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--main-white-color);
}

.iZ8KVm2rGACXqY0EQf4u {
  cursor: pointer;
}

@media (max-width: 960px) {
  .r5EEbPSJuc1Mx5Xmp0Vp {
    padding-left: 15px;
  }

  .XuihZF2dfQLteQa8wqLg {
    padding-right: 5px;
    margin-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ContentTitleAdaptive/ContentTitleAdaptive.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;;EAEzB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,8BAA8B;EAC9B,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,UAAU;AACZ;;AAEA;;EAEE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,yCAAyC;AAC3C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB;AACF","sourcesContent":[".titleWrap {\r\n  margin-bottom: 15px;\r\n  width: 100%;\r\n}\r\n\r\n.title {\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 18px;\r\n  line-height: 20px;\r\n  text-transform: uppercase;\r\n\r\n  color: var(--main-Gray-3-color);\r\n}\r\n\r\n.title_end_icon {\r\n  display: flex;\r\n  width: 98%;\r\n  max-width: 660px;\r\n  justify-content: space-between;\r\n  padding: 0;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.title_end_icon img {\r\n  margin-right: 3px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.popUpFilterOverlay {\r\n  background-color: rgba(0, 0, 0, 0.6);\r\n  position: fixed;\r\n  top: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  z-index: 1;\r\n}\r\n\r\n.popUpFilter {\r\n\r\n  width: 304px;\r\n  height: 720px;\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  background-color: var(--main-white-color);\r\n}\r\n\r\n.icon {\r\n  cursor: pointer;\r\n}\r\n\r\n@media (max-width: 960px) {\r\n  .title {\r\n    padding-left: 15px;\r\n  }\r\n\r\n  .title_end_icon {\r\n    padding-right: 5px;\r\n    margin-bottom: 0;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrap": `os48Mr4mcbhyLXB73ED7`,
	"title": `r5EEbPSJuc1Mx5Xmp0Vp`,
	"title_end_icon": `XuihZF2dfQLteQa8wqLg`,
	"popUpFilterOverlay": `e_Ur4uvvmvbleYCjK66l`,
	"popUpFilter": `Otf5frTQOhKGaNnBN729`,
	"icon": `iZ8KVm2rGACXqY0EQf4u`
};
export default ___CSS_LOADER_EXPORT___;
