var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from "./ContentTitleAdaptive.module.css";
import Filter_icon from "../../assets/img/Filter_icon.svg";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch } from "../../redux/store";
import { setActive, setModal } from "../../redux/modalReducer";
export function ContentTitleAdaptive(props) {
    var isMobile = useMediaQuery({
        query: "(max-width: 480px)",
    });
    var isPlanshet = useMediaQuery({
        query: "(max-width: 1240px)",
    });
    var dispatch = useAppDispatch();
    var setModalName = function (modal_name) {
        dispatch(setModal(modal_name));
        dispatch(setActive(true));
    };
    return (_jsx("div", __assign({ className: style.titleWrap }, { children: props.title !== "СОБЫТИЯ" && props.title !== "ВАКАНСИИ" && props.title !== "КОМПАНИИ"
            ? isPlanshet && _jsx("h2", __assign({ className: style.title }, { children: props.title }))
            : isPlanshet && (_jsxs("div", __assign({ className: style.title_end_icon }, { children: [_jsx("h2", __assign({ className: style.title }, { children: props.title })), _jsx("div", __assign({ style: { display: "flex", alignItems: "flex-end", paddingRight: "5px" } }, { children: _jsx("img", { className: style.icon, onClick: (props === null || props === void 0 ? void 0 : props.onClickLogic) ? props === null || props === void 0 ? void 0 : props.onClickLogic : function () { return setModalName(props.modalName); }, src: Filter_icon, alt: "filter" }) }))] }))) })));
}
